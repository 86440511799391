import React, { useState } from 'react';
import Video from '../../../components/Video';
import styled from 'styled-components/macro';

import AppStore from '../DownloadUs/assets/app-store.png';
import GooglePlay from '../DownloadUs/assets/google-play.png';

import {
  Container,
  MainWrap,
  LeftWrap,
  RightWrap,
  StyledPlayIcon,
  FlexDiv,
  Title,
  Description,
  StyledButton,
  StyledLink,
  MobileDiv,
  StyledImage,
  AppStoreDiv,
  H4,
  DesktopImageContainer,
  MobileImageContainer
} from './styles';

const H4Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

// TODO(Matt): Delete TopHeader V1 once all pages have been optimized
function TopHeader({
  color,
  backgroundImg,
  mobileBackgroundImg,
  title,
  description,
  showVideo = false,
  btn1,
  btn2,
  secondary,
  appleLink,
  googleLink,
  extraMargin,
  isTerms,
  isPrivacy,
  h4,
  h4Supplementary,
  main,
  backgroundMobileHeight,
  videoTitle,
  videoSrcURL,
  playiconmargintop,
  mobilePaddingTop,
  hidePlay
}) {
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  return (
    <Container
      isPrivacy={isPrivacy}
      isTerms={isTerms}
      backgroundImage={Boolean(backgroundImg) || Boolean(mobileBackgroundImg)}
      extraHeight={googleLink && appleLink}
      backgroundMobileHeight={backgroundMobileHeight}
      mobilePaddingTop={mobilePaddingTop}
    >
      {backgroundImg && (
        <DesktopImageContainer>{backgroundImg}</DesktopImageContainer>
      )}
      {mobileBackgroundImg && (
        <MobileImageContainer>{mobileBackgroundImg}</MobileImageContainer>
      )}
      <MainWrap>
        <LeftWrap>
          <H4Wrapper>
            {h4 && <H4 color={color}>{h4}</H4>}
            {h4Supplementary ? h4Supplementary : <></>}
          </H4Wrapper>
          <FlexDiv>
            <Title color={color}>{title}</Title>
          </FlexDiv>
          <Description
            extraMargin={extraMargin}
            color={color}
            backgroundImg={backgroundImg}
            mobileBackgroundImg={mobileBackgroundImg}
          >
            {description}
          </Description>
          <FlexDiv main={main ? 'true' : undefined}>
            {googleLink && appleLink ? (
              <AppStoreDiv>
                <a
                  href={googleLink}
                  style={{ all: 'unser' }}
                  onClick={() => {
                    window.analytics.track('gogetter_app_download', {
                      category: 'android',
                      link: { googleLink },
                      label: 'hero'
                    });
                  }}
                >
                  <StyledImage aria-label="GooglePlay" src={GooglePlay} />
                </a>
                <a
                  href={appleLink}
                  style={{ all: 'unser' }}
                  onClick={() => {
                    window.analytics.track('gogetter_app_download', {
                      category: 'ios',
                      link: { appleLink },
                      label: 'hero'
                    });
                  }}
                >
                  <StyledImage aria-label="AppStore" src={AppStore} />
                </a>
              </AppStoreDiv>
            ) : (
              <>
                {btn1 && (
                  <StyledLink
                    role="button"
                    to={btn1?.link || null}
                    className={`button ${secondary ? 'secondary' : ''}`}
                  >
                    <StyledButton
                      onClick={() => {
                        btn1.onClick && btn1.onClick();
                        window.analytics.track('hero_button', {
                          category: btn1.category || btn1.text,
                          label: btn1.link
                        });
                      }}
                      main={main ? 'true' : undefined}
                      marginright="true"
                      className={`button ${secondary ? 'secondary' : ''}`}
                    >
                      {btn1.text}
                    </StyledButton>
                  </StyledLink>
                )}
                {btn2 && (
                  <StyledLink
                    role="button"
                    to={btn2.link}
                    className={`button ${secondary ? 'secondary' : ''}`}
                  >
                    <StyledButton
                      onClick={() => {
                        btn2.onClick && btn2.onClick();
                        window.analytics.track('hero_button', {
                          category: btn2.category || btn2.text,
                          label: btn2.link
                        });
                      }}
                      main={main ? 'true' : undefined}
                      className={`button ${secondary ? 'secondary' : ''}`}
                    >
                      {btn2.text}
                    </StyledButton>
                  </StyledLink>
                )}
                {showVideo && (
                  <MobileDiv>
                    {' '}
                    {!hidePlay && (
                      <StyledPlayIcon
                        playiconmargintop={playiconmargintop}
                        onClick={() => setShowVideoPopup(true)}
                      />
                    )}{' '}
                  </MobileDiv>
                )}
              </>
            )}
          </FlexDiv>
        </LeftWrap>
        {showVideo && (
          <RightWrap>
            {!hidePlay && (
              <StyledPlayIcon onClick={() => setShowVideoPopup(true)} />
            )}
          </RightWrap>
        )}
      </MainWrap>
      {showVideo && (
        <Video
          videoSrcURL={videoSrcURL}
          videoTitle={videoTitle}
          open={showVideoPopup}
          onClose={() => setShowVideoPopup(false)}
        />
      )}
    </Container>
  );
}

export default TopHeader;
